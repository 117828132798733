import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Container,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Avatar,
  styled, Menu, MenuItem, Divider,
  useTheme,
} from "@mui/material";
import { Icon } from '@iconify/react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import docLogo from "../assets/LogoManPower.png";
import { resetAuth, resetFile, resetParticipantsMassive, resetUsersPrevious } from "../store/actions";

const MenuButton = styled(Button)(({ theme }) => ({
  margin: "2px 0px",
  // borderRight: `1px solid ${theme.palette.secondary.main10}`,
  // display: "flex",
  // alignItems: "left",
  // alignContent: "left",
  textAlign: 'left',
  fontSize: 14.8,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main10,
    color: '#231F20'
  },
  height: '42px',
  borderRadius: '7px',
  "& .MuiButton-startIcon": {
    marginBottom: 2,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 13.7,
  },
}));

const menuItems = [
  {
    name: "Inicio",
    icon: <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}><Icon icon="mdi-light:home" /></Box>,
    link: "/inbox/dashboards",
  },
  {
    name: "Bandeja",
    icon: <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}><Icon icon="ion:file-tray-full-outline" /></Box>,
    link: "/inbox/files",
  },
  {
    name: "Nuevo",
    icon: <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}><Icon icon="carbon:new-tab" /></Box>,
    link: "/inbox/new-file",
  },
  {
    name: "Buscar",
    icon: <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}><Icon icon="akar-icons:search" /></Box>,
    link: "/inbox/search",
  },
  {
    name: "Verificar documento",
    icon: <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}><Icon icon="ic:outline-security" /></Box>,
    link: "/inbox/verify-xml",
  },
  {
    name: "Usuarios",
    icon: <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}> <Icon icon="ph:users-light" fontSize={25} /></Box>,
    link: "/inbox/users",
  },
  {
    name: "Planes",
    icon: <Box sx={{ ml: 1, display: "flex", alignItems: "center" }}><Icon icon="fluent:payment-28-regular" /></Box>,
    link: "/inbox/payments",
  }, {
    name: "Plantillas",
    icon: <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}> <Icon icon="mdi:file-document-outline" fontSize={25} /></Box>,
    link: "/inbox/TemplateManager",
  },
];

export const HeaderAppBar = (props) => {
  const stateRedux = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const newFile = useSelector((state) => state.NewFile?.editStack);
  let optionsHeader = stateRedux?.Authentication?.items?.UserLoginData?.SecurityLoginData?.Applications[0]
    ?.Modules[0]?.Functions;
  const [anchorEl, setAnchorEl] = useState(null);

  let rol =
    stateRedux?.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles[0]
      ?.Name;

  let nameUser =
    stateRedux?.Authentication?.items?.UserLoginData.Email

  console.log('nameUser', nameUser)

  const cerrarSesion = () => {
    sessionStorage.clear();
    dispatch(resetAuth())
    navigate("/auth/login");
  };

  const getNavItemActive = (menuLink) => {
    return location.pathname.includes(menuLink);
  };

  const resetData = () => {
    dispatch(resetParticipantsMassive())
    dispatch(resetUsersPrevious());
    dispatch(resetFile());
    sessionStorage.removeItem('FileId');
  }

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#FFFFFF", color: "black", height: 'auto',
        maxWidth: 1400,
        border: '1px solid white',
        mx: "auto"
      }}
    >
      <Container maxWidth="xl" sx={{ p: 0, m: 0, padding: '0px !important', maxWidth: '100%' }}>
        <Toolbar
          disableGutters
          sx={{
            p: 0, m: 0, mr: { xs: 0, sm: 0 }, ml: { xs: 0, sm: 0, md: 0 },
            [theme.breakpoints.up("lg")]: {
              flexDirection: 'column !important',
              height: '100vh',
            },
            [theme.breakpoints.down("lg")]: {
              flexDirection: 'column !important',
              height: '100vh',
            },
            [theme.breakpoints.down("md")]: {
              flexDirection: 'column !important',
              height: '5vh',
            },
            [theme.breakpoints.down("sm")]: {
              height: '5vh',
            },
          }}
        >
          {/* mobile init */}
          <Box sx={{ display: { xs: "flex", md: "none" }, width: "100%" }}>
            <Box sx={{ flexGrow: 1, width: "100%" }}>
              <IconButton onClick={props.openDrawer} size="large" color="inherit">
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: 'center',
                // ml: 10,
                mt: 1,
                [theme.breakpoints.down("md")]: {
                  justifyContent: "end",
                  // mr: 1,
                },
                [theme.breakpoints.down("sm")]: {
                  justifyContent: "end",
                  mr: 1,
                },
              }}
            >
              <Box>
                <img src={docLogo} alt="docsolutions" style={{ width: 120 }} />
              </Box>
            </Box>
          </Box>
          {/* mobile finis */}

          {/* web init */}
          <Box sx={{
            display: { xs: "none", md: "flex" }, mr: 2, ml: 2, mt: 2,
            justifyContent: "center",
            mb: 2, pb: 2, borderBottom: '0.5px solid #C5C8CD', width: '90%'
          }}>
            <img src={docLogo} alt="docsolutions" style={{ width: 180 }} />
          </Box>
          <Box sx={{
            display: { xs: "none", md: "flex" }, mr: 2, ml: 2, mt: 2,
            mb: 0, pb: 1, color: '#BBC0C6', width: '90%'
          }}>
            <Typography variant="subtitle1" sx={{ color: 'secondary.main80', fontWeight: 550 }}>
              Menú
            </Typography>
          </Box>
          <Box sx={{
            flexGrow: 1, display: { xs: "none", md: "flex" }, width: '90%',
            [theme.breakpoints.up("lg")]: {
              flexDirection: 'column !important',
            },
            [theme.breakpoints.down("lg")]: {
              flexDirection: 'column !important',
            },
            [theme.breakpoints.down("md")]: {
              flexDirection: 'column !important',
            },
          }}>
            {nameUser == "tech.hub@docsolutions.com" ?
              menuItems?.map((menuItems, index) => (
                menuItems.name !== "Planes" ?
                  <MenuButton
                    key={index}
                    component={Link}
                    to={menuItems.link}
                    startIcon={menuItems.icon}
                    onClick={menuItems.name === 'Nuevo' ? (() => resetData()) : null}
                    sx={{
                      display: "flex",
                      justifyContent: 'left',
                      alignContent: "center",
                      textAlign: "left",
                      ml: 2,
                      fontWeight: getNavItemActive(menuItems.link)
                        ? 500
                        : 300,
                      color: getNavItemActive(menuItems.link)
                        ? "primary.mainLyrics"
                        : "secondary.main80",
                      backgroundColor: getNavItemActive(menuItems.link)
                        ? "primary.selectMenu"
                        : 'transparent'
                    }}
                  >
                    <span style={{ minWidth: '55px' }}>
                      {menuItems.name === "Nuevo" && newFile === true
                        ? "Borrador"
                        : menuItems.name}
                    </span>
                  </MenuButton> : null
              )
              )
              :
              optionsHeader?.map((item) => (
                menuItems?.map((menuItems, index) => (
                  item.Name === menuItems.name ? (
                    <MenuButton
                      key={index}
                      component={Link}
                      to={menuItems.link}
                      startIcon={menuItems.icon}
                      onClick={menuItems.name === 'Nuevo' ? (() => resetData()) : null}
                      sx={{
                        display: "flex",
                        justifyContent: 'left',
                        alignContent: "center",
                        textAlign: "left",
                        ml: 2,
                        fontWeight: getNavItemActive(menuItems.link)
                          ? 500
                          : 300,
                        color: getNavItemActive(menuItems.link)
                          ? "primary.mainLyrics"
                          : "secondary.main80",
                        backgroundColor: getNavItemActive(menuItems.link)
                          ? "primary.selectMenu"
                          : 'transparent'
                      }}
                    >
                      <span style={{ minWidth: '55px' }}>
                        {menuItems.name === "Nuevo" && newFile === true
                          ? "Borrador"
                          : menuItems.name}
                      </span>
                    </MenuButton>
                  ) : null
                ))))
            }
          </Box>

          <Box sx={{ flexGrow: 0.1, ml: 2, display: { xs: "none", md: "flex" }, borderTop: `1.5px solid ${theme.palette.secondary.main60}`, }}>
            <Box sx={{ display: "flex", mr: 2, mt: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Avatar
                  sx={{
                    color: "primary.mainLyrics",
                    backgroundColor: "primary.main",
                    fontWeight: 600,
                    mt: 0.7
                  }}
                  alt={sessionStorage.userName}
                  src="/static/images/avatar/2.jpg"
                />
              </Box>
              <Box sx={{ p: .5, cursor: "cursor", display: "flex", justifyContent: "right", flexDirection: "column" }}>
                <Box sx={{ display: 'flex', alignItems: "center" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ display: "flex", fontSize: '17px', pl: 1 }}
                  >
                    {sessionStorage.userNameDisplay}
                  </Typography>
                  <KeyboardArrowUpIcon
                    onClick={event => {
                      setAnchorEl(event.currentTarget);
                    }}
                    sx={{ color: 'secondary.main', ml: 3 }}
                  />
                </Box>

                <Typography variant="body" sx={{ display: "flex", fontSize: '12px', pl: 1 }}>
                  {rol === 'Operador' ? 'Participante' : rol}
                </Typography>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={e => {
                    setAnchorEl(null);
                  }}
                  PaperProps={{
                    style: {
                      backgroundColor: '#ECECEC',
                      color: '#000000',
                    },
                  }}
                >
                  <MenuItem
                    component={Link}
                    to={`/inbox/profileUser?tab=${0}`}
                    onClick={() => setAnchorEl(null)}
                  >
                    Editar perfil
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    component={Link}
                    to={`/inbox/profileUser?tab=${1}`}
                    onClick={() => setAnchorEl(null)}
                  >
                    Cambiar contraseña
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={cerrarSesion}>Cerrar sesión</MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
          {/* web init */}
        </Toolbar >
      </Container >
    </AppBar >
  );
};
