import { alpha } from "@mui/material";

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#28A745",
  darker: "#08660D",
  contrastText: "#212B36",
};

/* const ERROR = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
}; */

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#476EA5",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const INFO = {
  main: "#FFC222",
};

export const palette = {
  primary: {
    dark: "#476EA5",
    main: "#476EA5",
    main100: alpha("#476EA5"),
    main80: alpha("#476EA5", 0.8),
    main60: alpha("#476EA5", 0.6),
    main40: alpha("#476EA5", 0.4),
    main20: alpha("#476EA5", 0.2),
    main10: alpha("#476EA5", 0.1),
    lightContrast: "#FFFFFF",
    darkContrast: "#000000",
    contrastText: "#FFFFFF",
    TableHeader: "#476EA5",
    TableHeader2: "#476EA5",
    text:"#424242",
    selectMenu: "#476EA5",
    borderASummary:"#EEEEEE",
    mainLyrics: '#FFFFFF'
  },
  secondary: {
    dark: "#000000",
    main: "#231F20",
    main80: "#3F3F3E",
    main60: "#A0A1A2",
    main40: "#B9B9B9",
    main20: "#D1D1D1",
    main10: "#E8E8E8",
    lightContrast: "#FFFFFF",
    darkContrast: "#000000",
    contrastText: "#FFFFFF",
    warningAlert: "rgba(255, 184, 0, 0.35)",
  },
  success: { ...SUCCESS },
  error: { ...ERROR },
  info: { ...INFO },
  nabvar: {
    main: "#476EA5",
  },
  table: {
    main: "#243746",
    secondary: "#476EA5",
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};
